import { Controller } from "@hotwired/stimulus";
import algoliasearch, { SearchClient } from "algoliasearch/lite";
import instantsearch, { InstantSearch } from "instantsearch.js/es";
import { index } from "instantsearch.js/es/widgets";
import { searchBox, hits, configure } from "instantsearch.js/es/widgets";
import ahoy from "ahoy.js";

export default class extends Controller {
  static values = {
    algoliasearchApplicationId: String,
    algoliasearchSearchOnlyApiKey: String,
    indexName: String,
    initialKeyword: String,
  };
  static targets = ["searchbox", "tab", "result"];
  declare readonly algoliasearchApplicationIdValue: string;
  declare readonly algoliasearchSearchOnlyApiKeyValue: string;
  declare readonly indexNameValue: string;
  declare readonly initialKeywordValue: string;
  declare readonly searchboxTarget: HTMLElement;
  declare readonly tabTargets: HTMLElement[];
  declare readonly resultTargets: HTMLElement[];

  connect(): void {
    this.initializeSearch();
  }

  initializeSearch() {
    const searchClient: SearchClient = algoliasearch(
      this.algoliasearchApplicationIdValue,
      this.algoliasearchSearchOnlyApiKeyValue,
    );

    const search: InstantSearch = instantsearch({
      indexName: this.indexNameValue,
      searchClient: searchClient,
      initialUiState: {
        [this.indexNameValue]: {
          query: this.initialKeywordValue,
        },
      },
      searchFunction: (helper) => {
        const currentQuery = helper.state.query;
        if (currentQuery) {
          ahoy.track("search", { query: currentQuery });
          const url = new URL(window.location.href);
          url.searchParams.set("keyword", currentQuery);
          window.history.replaceState({}, "", url);
        }
        helper.search();
      },
      insights: {
        insightsInitParams: {
          useCookie: true,
        },
      },
    });

    const templates = {
      staffs: {
        item: (hit, { html, components }) => {
          const imageContent = hit.thumbnail_path
            ? html`<img src="${hit.thumbnail_path}" class="style-thumbnail" />`
            : html`<div class="style-thumbnail"></div>`;

          return html`<a href="/salons/${hit.salon_id}/staffs/${hit.staff_id}/">
            ${imageContent}
            <h2
              class="hidden whitespace-nowrap text-ellipsis text-xs overflow-hidden text-gray-700"
            >
              ${components.Highlight({ hit, attribute: "staff_name" })}
            </h2>
          </a> `;
        },
        empty:
          "お探しのスタッフはありません。<br />別のキーワードをお試しください。",
      },
      styles: {
        item: (hit, { html, components }) => {
          const imageContent = hit.thumbnail_path
            ? html`<img src="${hit.thumbnail_path}" class="style-thumbnail" />`
            : html`<div class="style-thumbnail"></div>`;

          return html`<a href="/salons/${hit.salon_id}/styles/${hit.objectID}/">
            ${imageContent}
            <h2
              class="hidden whitespace-nowrap text-ellipsis text-xs overflow-hidden text-gray-700"
            >
              ${components.Highlight({ hit, attribute: "name" })}
            </h2>
          </a> `;
        },
        empty:
          "お探しのスタイルはありません。<br />別のキーワードをお試しください。",
      },
      salons: {
        item: (hit, { html, components }) => {
          const imageContent = hit.thumbnail_path
            ? html`<img src="${hit.thumbnail_path}" class="salon-thumbnail" />`
            : html`<div class="salon-thumbnail"></div>`;

          return html`<a href="/salons/${hit.objectID}/">
            ${imageContent}
            <h2
              class="whitespace-nowrap text-ellipsis text-xs overflow-hidden text-gray-700 mb-3"
            >
              ${components.Highlight({ hit, attribute: "name" })}
            </h2>
          </a> `;
        },
        empty:
          "お探しのサロンはありません。<br />別のキーワードをお試しください。",
      },
      posts: {
        item: (hit, { html, components }) => {
          const imageContent = hit.thumbnail_path
            ? html`<img src="${hit.thumbnail_path}" class="post-thumbnail" />`
            : html`<div class="post-thumbnail"></div>`;

          const query =
            search.renderState[this.resultTargets[0].dataset.indexName]
              .searchBox.query;

          const url =
            query === ""
              ? `/posts/${hit.objectID}/`
              : `/search/${query}/posts/${hit.objectID}/`;

          return html`<a href="${url}">
            ${imageContent}
            <h2
              class="hidden whitespace-nowrap text-ellipsis text-xs overflow-hidden text-gray-700"
            >
              ${components.Highlight({ hit, attribute: "salon_name" })}
            </h2>
          </a> `;
        },
        empty:
          "お探しのポストはありません。<br />別のキーワードをお試しください。",
      },
    };

    search.addWidgets([
      searchBox({
        container: this.searchboxTarget,
        placeholder: "検索",
        autofocus: true,
      }),
      configure({
        hitsPerPage: 30,
        filters: `visible:true AND thumbnail:true`,
      }),
      hits({
        container: this.resultTargets[0],
        templates: templates[this.resultTargets[0].dataset.key],
      }),
    ]);

    const createWidgets = (elemnt: HTMLElement) => {
      const additionalIndex = index({ indexName: elemnt.dataset.indexName });
      additionalIndex.addWidgets([
        hits({
          container: elemnt,
          templates: templates[elemnt.dataset.key],
        }),
      ]);
      return additionalIndex;
    };

    search.addWidgets([
      createWidgets(this.resultTargets[1]),
      createWidgets(this.resultTargets[2]),
      createWidgets(this.resultTargets[3]),
    ]);
    search.start();
  }

  selectTab(event) {
    const target = event.target.closest("[data-key]") as HTMLElement;
    const activeKey = target.dataset.key;
    this.tabTargets.forEach((tab: HTMLElement) => {
      if (tab.dataset.key === activeKey) {
        tab.classList.add("active");
      } else {
        tab.classList.remove("active");
      }
    });

    this.resultTargets.forEach((result: HTMLElement) => {
      if (result.dataset.key === activeKey) {
        result.classList.remove("hidden");
      } else {
        result.classList.add("hidden");
      }
    });
  }
}
